import Vue from 'vue'
import api from '../../api'

import { pickedDayInfoFromStart } from '@/utils'

const loadFromStore = function(key) {
  const json = localStorage.getItem(key)
  if (json) {
    return JSON.parse(json)
  }
}

const saveToStore = function(key, value) {
  if (value == null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const namespaced = true

const initialState = function() {
  return {
    start: null,
    status: null,
    frequency: null,
    requestedCleaning: null,
    //// extraId => quantity
    //// extraId => description (name X qty), for the summary widget
    pickedExtras: {},
    reservationId: null,
    someone_home: null,
    active_coupon: null,
    promo_code: null,
    confirmation_details: null,
    confirmation_title: null,
    confirmation_text: null,
    //// Should be in the app's TZ! UTC-8
    minDate: new Date(),
    flexible: {
      preferredContacts: [],
      description: null,
      preferredPeriod: null,
      preferredTime: null,
      preferredDays: [],
      confirm: false
    }
  }
}

export const state = initialState

export const getters = {
  active_coupon: state => state.active_coupon,
  reservationId: state => state.reservationId,
  requestedCleaning: state => state.requestedCleaning,
  frequency: state => state.frequency,
  start: state => state.start,
  status: state => state.status,
  pickedExtras: state => state.pickedExtras,
  someone_home: state => state.someone_home,
  confirmation_details: state => state.confirmation_details,
  confirmation_title: state => state.confirmation_title,
  confirmation_text: state => state.confirmation_text,
  promo_code: state => state.promo_code,
  isStartPicked: (state) => {
    return (state.start != null) && (state.start.length > 0)
  },
  isFlexibleBooking: state => state.status == 'flexible',
  flexible: state => state.flexible,
  dbg: (state, getters) => {
    return { start: state.start, status: state.status, schedule: getters.schedule }
  },
  schedule: (state, getters) => {
    var repeatOn, repeatBy
    var interval = 1
    var frequency = state.frequency
    if (frequency == null) {
      return
    }
    if (frequency == 'bi-weekly') {
      interval = 2
      frequency = 'weekly'
    }
    const dayInfo = pickedDayInfoFromStart(state.start, frequency)
    if (dayInfo) {
      var d = Date.parse(state.start)
      repeatBy = 'day_of_week'
      if (frequency == 'weekly') {
        repeatOn = dayInfo.dayInWeek
        if (repeatOn == 7) {
          repeatOn = 0
        }
      }
      if (frequency == 'monthly') {
        repeatBy = 'day_of_month'
        repeatOn = d.getDate()
        //repeatOn[dayInfo.dayInWeek] = [dayInfo.weekIdx + 1]
      }
      const schedule = {
        starts: state.start,
        interval: interval,
        frequency: frequency,
        repeat_on: JSON.stringify(repeatOn),
        repeat_by: repeatBy
      }
      return schedule
    }
  }
}
export const mutations = {
  RESET(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
      localStorage.removeItem(key)
    })
  },
  SET_ATTR(state, { attr, value }) {
    state[attr] = value
    saveToStore(attr, value)
  },
  SET_ACTIVE_COUPON(state, coupon_info) {
      state.active_coupon = coupon_info
  },
  REMOVE_ACTIVE_COUPON(state) {
      state.active_coupon = null
  },
  SET_START(state, value) {
    state.start = value
    saveToStore('start', value)
  },
  SET_STATUS(state, value) {
    state.status = value
    saveToStore('status', value)
  },
  SET_FREQUENCY(state, value) {
    state.frequency = value
    saveToStore('frequency', value)
  },
  SET_FLEXIBLE_OPTIONS(state, flexibleOptions) {
    state.flexible.description = flexibleOptions.description
    state.flexible.preferredPeriod = flexibleOptions.preferredPeriod
    state.flexible.preferredContacts = flexibleOptions.preferredContacts
    state.flexible.preferredDays = flexibleOptions.preferredDays
    state.flexible.preferredTime = flexibleOptions.preferredTime
    state.flexible.confirm = flexibleOptions.confirm
    saveToStore('flexible', state.flexible)
  },
  SET_REQUESTED_CLEANING(state, value) {
    state.requestedCleaning = value
    saveToStore('requestedCleaning', value)
  },
  UPDATE_PICKED_EXTRAS(state, details) {
    const extraId = details.extraId
    const quantity = details.quantity
    if (quantity > 0) {
      const basePrice = details.basePrice
      const name = details.name
      const perCost = details.perCost
      const includedQty = details.includedQty
      Vue.set(state.pickedExtras, extraId, {
        quantity: quantity, name: name, perCost: perCost, includedQty: includedQty, basePrice: basePrice
      })
    } else {
      Vue.delete(state.pickedExtras, extraId)
    }
    saveToStore('pickedExtras', state.pickedExtras)
  },
}
export const actions = {
  reset({ commit }) {
    return new Promise((resolve, reject) => {
      commit('RESET')
      resolve()
    })
  },
  setAttr({ commit }, attrAndValue) {
    commit('SET_ATTR', attrAndValue)
  },
  setRequestedCleaning({ commit }, cleaning) {
    commit('SET_REQUESTED_CLEANING', cleaning)
  },
  getCouponInfo({ commit, getters }) {
      let promoCode = getters.promo_code
        return new Promise((resolve, reject) => {
          api.getCouponInfo(promoCode).then(response => {
                commit('SET_ACTIVE_COUPON', response.data)
                resolve()
            }, (errors) => {
                commit('REMOVE_ACTIVE_COUPON')
                commit('SET_ATTR', { "attr": "promo_code", "value": null })
                reject(errors.response.data.error)
            })
    })
  },
  removeCouponInfo({ commit }) {
      commit('REMOVE_ACTIVE_COUPON')
      commit('SET_ATTR', { "attr": "promo_code", "value": null })
  },
  remember({ commit, getters }) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      saveToStore(key, getters[key])
    })
  },
  resume({ commit }) {
    //// FIXME there is bug which makes the status not to restore properly after reload,
    //// to avoid any problems, we clear the start too, so clients are forced to pick again
    //commit('SET_START', loadFromStore('start'))
    commit('SET_START', null)
    //commit('SET_STATUS', loadFromStore('status'))
    commit('SET_STATUS', null)
    commit('SET_ATTR', { attr: 'promo_code', value: loadFromStore('promo_code') })
    dispatch('getCouponInfo')
    commit('SET_REQUESTED_CLEANING', loadFromStore('requestedCleaning'))
    commit('SET_FREQUENCY', loadFromStore('frequency'))
    commit('UPDATE_PICKED_EXTRAS', loadFromStore('pickedExtras'))
  }
}
